body {
  margin: 0;
  /*
  background:url('http://zanamipravda.ru/wp-content/themes/zanamipravda/images/lenta-2.png') repeat 500px;
  */
  background-image: url("../public/background.png");
  background-repeat: repeat;
  background-size: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: Montserrat, sans-serif;
}

body *{
  margin: 0;
  padding: 0;
  color: white;
}

img{
  margin: 5px;
}


#header
{
  border-radius: 10px;
  font-size:30px;
  background: linear-gradient(130deg, rgb(255, 255, 255), rgb(0, 35, 255), #ff0000);
  background-size: 200% 200%;
  animation: Animation 2s linear infinite;
}
@-webkit-keyframes Animation {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@keyframes Animation {
  0% {
    background-position: 10% 0%
  }
  50% {
    background-position: 91% 100%
  }
  100% {
    background-position: 10% 0%
  }
}
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}
