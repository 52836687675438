* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    min-height: 100vh;
}

[class^="box-"] {
    background-color: skyblue;
    /* Размещаем контейнеры по центру */
    display: grid;
    place-items: center;
}

.container {
    display: grid;
    grid-template-columns: 200px 500px;
    grid-template-rows: 400px 45px;
    column-gap: 5px;
    row-gap: 5px;
    justify-items : stretch;
    align-items: start;
    align-content : center;
}
#profile-button{
    width: 200px;
}

.header{
    position: fixed;
    top: 10px;
}