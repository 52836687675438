.container{
    display: flex;
    margin: 10px 0 15px 0;
    direction: ltr;
}

.container .messageContent{
    background: rgba(255,255,255,0.2);
    border-radius: 5px 15px 15px 15px;
    padding: 5px;
    min-width: 100px;
}

.container .messageContent p {
    margin: 0;
    white-space: break-spaces;
    word-break: break-word;

}

.container .messageContent h6{
    margin: 0;
    overflow: clip;
    text-wrap: none;

}

