.container{
    display: flex;
    margin: 10px 10px 15px 10px;
    width: auto;
    padding: 5px;
    justify-content: right;
}

.container img{
    margin: 5px;
}

.container .messageContent{
    background: rgb(13, 110, 253, 0.3);
    border-radius: 10px;
    padding: 5px;
}

.container .messageContent p {
    max-width: 400px;
    margin: 0;
    padding: 0;
    white-space: break-spaces;
    word-break: break-word;
}

.container .messageContent h6{
    width: 300px;
    margin: 0;
    padding: 0;
    overflow: clip;
    text-wrap: none;
}

