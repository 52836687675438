.container{
    height: 400px;
    width: 100%;
    border: 1px #0d6efd solid;
    border-radius: 10px;
    background-color:rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    overflow-x: clip;
    white-space: nowrap;
}
.Header{
    text-align: center;
    margin: 0;
}

.container img {
    border-radius: 50%;
}

.container::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}

/* background of the scrollbar except button or resizer */
.container::-webkit-scrollbar-track {

}

.container::-webkit-scrollbar-corner{

}

/* scrollbar itself */
.container::-webkit-scrollbar-thumb {
    background-color: #0d6efd;
    border-radius: 15px;
}

/* set button(top and bottom of the scrollbar) */
.container::-webkit-scrollbar-button {
    display:none;
}
