.container{
    display: flex;
    margin: 10px 10px 15px 10px;
    width: 100%;
    justify-content: center;
    padding: 5px;
}

.container .messageContent{
    background: rgba(134, 161, 197, 0.3);
    border-radius: 10px;
    padding: 5px;
}

.container .messageContent p {
    max-width: 400px;
    margin: 0;
    padding: 0;
    white-space: break-spaces;
    word-break: break-word;
}

